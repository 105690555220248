<template>
  <div class="dashboard-main">
    <div class="appBar">
      <router-link :to="prefixPath">
        <img class="app-logo" src="https://gcdn.bionluk.com/site/general/bionluk_logo_gk.png">
      </router-link>
      <div @click="openSearchModal()" style="padding-right: 18px">
        <img src="https://gcdn.bionluk.com/site/icon/search_icon_haluk.png" alt="filter-icon" style="width: 18px">
      </div>
    </div>

    <div class="underAppBar"></div>
    <div v-for="category in parentCategories">

      <router-link class="categoryList nohover" :to="`/freelancer-bul/${category.slug}/`">
        <div class="category-list-inner">
          <p>
            {{ category.name }}
          </p>
          <img style="margin-right: 11px;" src="https://gcdn.bionluk.com/site/icon/tansel_mobil_ws_ok.svg">
        </div>
        <div class="seperator"></div>
      </router-link>
    </div>
  </div>
</template>

<script>

  export default {
    name: "src-pages-body-marketplace-dashboard-v2_mobile",
    data() {
      return {
        footerItems: ['Privacy', 'Terms', "Advertisement", "About"],
        duration: null
      }
    },
    methods: {
      openSearchModal() {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.SEARCH, info: { duration: this.duration }});
      }
    },
    created() {
      this.EventBus.$on('search', payload => {
        let url = encodeURI(`/search?term=${encodeURIComponent(payload.searchTerm.toLocaleLowerCase('tr-TR'))}`)

        this.$router.push(url);
      })
    },
    beforeDestroy() {
      this.EventBus.$off('search');
    }
  }
</script>

<style scoped>

  .dashboard-main {
    width: 100%;
    height: 100%;
    padding-bottom: 56px;
  }

  .appBar {
    z-index: 99;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
    display: flex;
    justify-content: space-between;
  }

  .category-list-inner {
    width: 100%;
    height: 58px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #8b95a1;
  }

  .seperator {
    width: 100%;
    height: 2px;
    border-bottom: solid 1px #dfe4ec;
  }

  .categoryList {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
  }


  .app-logo{
    height: 28px;
    margin-top:2px;
    margin-left: 15px;
  }


</style>
